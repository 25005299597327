<template>
  <div :class="componentVariantStyles.menu.wrapper">
    <div
      v-if="menuCategories.length"
      class="container"
    >
      <div class="relative flex flex-col xl:flex-nowrap">
        <p
          class="w-full shrink-0 pr-4 pt-9 font-bold xl:pt-10"
          :class="componentVariantStyles.header"
        >
          {{ props.header }}
        </p>
        <div class="w-full overflow-hidden">
          <UiScrollWrapper
            :scroll-to-center="setScrollPosition"
            :class-for-arrow-left="{ '!from-blue-650': props.type === 'wide' }"
            :class-for-arrow-right="{ '!to-blue-650': props.type === 'wide' }"
            variant="controlled"
          >
            <UiMenuTabs
              :menu="menuCategories"
              :disabled="status === 'pending'"
              :color-variant="props.type === 'wide' ? 'white' : 'blue'"
              :class="{ 'cursor-wait': status === 'pending' }"
              @click="setActiveCategory($event)"
            />
          </UiScrollWrapper>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="products"
    :class="componentVariantStyles.productsContainer"
  >
    <UiIcon
      v-if="props.type === 'compact'"
      name="logo-short"
      :width="1056"
      :height="578"
      class="absolute inset-y-0 left-0 h-96 -translate-x-1/4 text-white opacity-15 xl:top-[215px] xl:h-auto xl:max-w-full xl:translate-x-0"
    />
    <div class="container overflow-hidden xl:overflow-visible">
      <div
        class="-mr-8 flex flex-wrap py-7 xl:mr-0 xl:flex-nowrap"
        :class="[
          props.type === 'compact' ? 'items-center xl:pb-24 xl:pt-14' : 'xl:pt-10',
        ]"
        :style="props.type === 'wide' && (status === 'pending' || !isVisible) ? { minHeight: '645px' } : {}"
      >
        <div
          v-if="props.type === 'compact'"
          class="z-10 w-full pr-4 xl:w-1/4"
        >
          <UiHeader
            v-if="activeCategory?.magentoData?.header"
            :header="activeCategory?.magentoData?.header"
            :header-layout="props.headerLayout"
            header-style-class="text-white w-1/2 xl:w-full mb-11 xl:mb-9 text-xl lg:text-4xl"
          />
          <UiButton
            v-if="categoryURL && categoryHeader && menuCategories.length"
            class="!hidden !text-white underline-offset-2 transition-all hover:!underline xl:!inline-flex"
            variant="link"
            :uppercase="false"
            :to="categoryURL"
            icon-right="arrow"
          >
            {{ categoryHeader }}
          </UiButton>
        </div>

        <div
          v-if="!isMounted && props.type === 'wide'"
          class="flex w-full gap-2.5"
        >
          <Skeletor
            v-for="item in 6"
            :key="item"
            as="div"
            class="h-full"
          />
        </div>

        <template v-if="props.type === 'compact'">
          <LazyUiTransition
            name="fade-slide"
            mode="out-in"
          >
            <MagentoCategoryProductsSliderCompact
              v-if="isVisible"
              ref="categoryProducts"
              :products="products"
              :autoplay="true"
              :options="sliderOptions"
              :class="[{ 'xl:w-3/4': props.type === 'compact' }]"
            />
          </LazyUiTransition>
        </template>

        <ClientOnly>
          <template v-if="props.type === 'wide'">
            <template v-if="status === 'pending' || !isVisible">
              <div class="hidden w-full gap-2.5 lg:flex">
                <Skeletor
                  v-for="item in 6"
                  :key="item"
                  as="div"
                  class="h-full"
                />
              </div>

              <div class="flex w-full gap-2.5 lg:hidden">
                <Skeletor
                  v-for="item in 2"
                  :key="item"
                  as="div"
                  class="h-full"
                />
              </div>
            </template>

            <MagentoCategoryProductsSliderWide
              v-else
              ref="categoryProducts"
              :products="products"
              :autoplay="true"
              :options="sliderOptions"
            />
          </template>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ComponentPublicInstance } from 'vue'
import { ref } from 'vue'
import { useAppNav } from '@base/components/AppNav/useAppNav'
import { base64encode } from '@base/utils/base64'
import UiScrollWrapper from '@ui/components/helpers/UiScrollWrapper/UiScrollWrapper.vue'
import type { MenuItem } from '@ui/components/UiMenuTabs/UiMenuTabs.types'
import { useProductQueries } from '@ecom/composables/product/useProductQueries/useProductQueries'
import useIndividualProductData from '@ecom/composables/product/useIndividualProductData'
import { useCustomer } from '@customer/composables/useCustomer'
import { Skeletor } from 'vue-skeletor'
import MagentoCategoryProductsSliderWide from '../MagentoCategoryProductsSlider/MagentoCategoryProductsSliderWide/MagentoCategoryProductsSliderWide.vue'
import MagentoCategoryProductsSliderCompact from '../MagentoCategoryProductsSlider/MagentoCategoryProductsSliderCompact/MagentoCategoryProductsSliderCompact.vue'
import type { T3CeRawlplugMagentoCategoriesProps } from './T3CeRawlplugMagentoCategories.types'
import type { GetProductByCategoryIdQuery } from '#gql'

/**
 * This component is used to display products from:
 * - products from multiple categories with menu (categories)
 * - specific category (magentoCategoryId)
 * - specific products (productSkus)
 */

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<T3CeRawlplugMagentoCategoriesProps>(), {
  type: 'compact',
  headerLayout: 2,
  file: null,
  categories: undefined,
  magentoCategoryId: undefined,
  productSkus: undefined,
})

const isMounted = ref<boolean>(false)
const setScrollPosition = ref<boolean>(false)
const currentCategoryId = ref<string>('')
const isVisible = ref<boolean>(false)
const { categoryLink } = useAppNav()
const { isLoggedIn } = useCustomer()
const { getIndividualProductsData } = useIndividualProductData()

onMounted(() => {
  isMounted.value = true
  isVisible.value = true
})
onUnmounted(() => {
  isMounted.value = false
  isVisible.value = false
})

const menuCategories = computed<MenuItem[]>(() => {
  if (!props.categories) { return [] }
  return props.categories
    .filter(item => item?.title || item?.magentoCategoryId)
    .map((item, index) => {
      const isActive
        = currentCategoryId.value === item.magentoCategoryId
      return {
        title: item?.title,
        active: currentCategoryId.value === '' ? index === 0 : isActive,
        magentoData: {
          categoryId: item.magentoCategoryId,
          header: item.header,
        },
      }
    })
})

const activeCategory = computed(() => {
  if (props.categories) {
    return menuCategories.value.find(item => item.active === true)
  }
  if (props.magentoCategoryId) {
    return {
      magentoData: {
        categoryId: props.magentoCategoryId,
        header: props.header,
      },
    }
  }
  if (props.productSkus) {
    return {
      magentoData: {
        header: props.header,
      },
    }
  }
  return null
})

function setActiveCategory(item: MenuItem) {
  currentCategoryId.value = item?.magentoData?.categoryId
  setScrollPosition.value = true
  isVisible.value = false
  setTimeout(() => {
    setScrollPosition.value = false
  }, 100)
  refresh().then(() => setSlider())
}

const categoryProducts = ref<{
  productsSlider: ComponentPublicInstance<typeof MagentoCategoryProductsSliderWide | typeof MagentoCategoryProductsSliderCompact>
} | null>(null)

function setSlider() {
  categoryProducts.value?.productsSlider?.swiper?.slideTo(0)
  setTimeout(() => {
    isVisible.value = true
  }, 500)
}

const {
  data: productsData,
  refresh,
  status,
} = await useAsyncData<GetProductByCategoryIdQuery>(
  `rawlplug-magento-categories-${props.uid || props.id}`,
  () => {
    const { getProductByCategoryId } = useProductQueries()

    if (props.productSkus) {
      return getProductByCategoryId({
        productSkus: props.productSkus,
        fetchPrice: props.type === 'wide',
      })
    }

    return getProductByCategoryId({
      category_uid: base64encode(activeCategory.value?.magentoData?.categoryId),
      fetchPrice: props.type === 'wide',
      fetchCategoryUrl: Boolean(props.magentoCategoryId),
    })
  },
)

const products = computed(() => productsData.value?.products?.items ?? [])

await useAsyncData(
    `rawlplug-magento-categories-customer-individual-data-${props.uid || props.id}`,
    async () => {
      if (isLoggedIn.value && props.type === 'wide' && products.value.length) {
        return await getIndividualProductsData(products.value)
      }
      return null
    },
    {
      watch: [isLoggedIn, products],
    },
)

const categoryURL = computed(() => {
  const link = productsData.value?.categories?.items?.[0]?.canonical_url
  if (!link) { return null }
  return categoryLink(link)
})

const categoryHeader = computed(() => {
  if (props.categories) {
    return props.subheader
  }
  if (props.magentoCategoryId) {
    return props.header
  }

  return ''
})

const componentVariantStyles = computed(() => {
  return {
    header: [props.type === 'wide' ? 'text-xl xl:text-2xl text-white mb-10' : 'text-primary text-lg xl:text-lg mb-5'],
    menu: {
      wrapper: [props.type === 'wide' ? 'bg-blue-650' : 'bg-white min-h-'],
    },
    productsContainer: [props.type === 'wide' ? 'bg-white' : 'bg-gradient-bright-blue relative overflow-hidden'],
  }
})

const sliderOptions = computed(() => {
  if (props.type === 'wide') {
    return {
      slidesPerView: 1.6,
      spaceBetween: 10,
      breakpoints: {
        640: {
          slidesPerView: 2,
          slidesOffsetAfter: 0,
        },
        1024: {
          slidesPerView: 3,
          slidesOffsetAfter: 0,
        },
        1300: {
          slidesPerView: 4,
          slidesOffsetAfter: 0,
        },
        1366: {
          slidesPerView: 6,
          slidesOffsetAfter: 0,
        },
      },
    }
  }

  return {
    slidesPerGroup: 2,
    slidesPerView: 1.5,
  }
})
</script>
